import React from 'react';


function Signup() {
  return (
    <div className="authContainer" >
      
    </div>
  )
}

export default Signup